.header {
    @include flex(row, null, center, null, flex);

    &-top {
        background: #fff;
        width: 100%;
        height: 75px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        position: fixed;
        box-shadow: 0 10px 64px 0 rgba(0,0,0,.05);

    }

}

@include media(tablet) {

    .header {

        &-top {
            height: 120px;

        }

    }

}
@include media(desktop) {

    .header{
        padding: 20px;
        box-sizing: border-box;

    }

}