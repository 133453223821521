.container {

    width: calc( 100% - 30px );
    margin: 0 15px;
    padding: 10rem 0;
    box-sizing: border-box;

    @include media(tablet) {
        width: 760px;
        margin: 0 auto;
    }

    @include media(desktop) {
        width: 960px;
    }

    @include media(laptop) {
        width: 1000px;
    }

    @include media(fhd) {
        width: 1200px;
    }

}

.page,
.post {

    &-container {
        max-width: 760px;
        padding-top: 0;

    }

}