.blog {
    
    &-list {
        @include flex(column, wrap, center, center, flex);
        margin: 4rem 0 0;

    }

    &-item {
        display: block;
        color: #000;
        margin: 10px;
        @include transition();
        max-width: 350px;

        &:hover {
            transform: scale(1.05);

        }

        &__content {
            padding: 0 15px;

        }

        &__info {
            @include flex(row, null, center, space-between, flex);
            margin: 15px 0 0;

        }

        &__date {
            font-size: 1.5rem;
            font-weight: $bold;
            color: $indigo;

        }

        &__category {
            @include radius();
            font-size: 1.5rem;
            text-transform: uppercase;
            background: $indigo;
            color: #fff;
            padding: 5px 30px;

        }

        h4 {
            font-size: 1.8rem;
            margin: 15px 0 0;
        }

        img {
            @include radius();

        }

    }

    &-row {
        @include flex(row, wrap, center, center, flex);
    }

    &-pagenavi {
        text-align: center;
        font-size: 1.6rem;
        margin-top: 2rem;

        .pages,
        .page,
        .nextpostslink,
        .previouspostslink,
        .current {
            @include radius();
            background: $indigo;
            color: #fff;
            border: 0;
            padding: 15px 30px;

        }

        .page,
        .nextpostslink {
            padding: 15px 25px;
        }

        .current {
            background: rgb(218, 218, 218);
            color: $indigo;

        }

    }

    &-category,
    &-tags {
        display: block;
        background: $indigo;
        border-radius: 30px;
        padding: 15px 30px;
        color: #fff!important;
        transition: 0.2s all ease-in-out;
        font-size: 1.4rem;
        margin-top: 15px;

        &:hover {
            background: $green;
            color: #fff;
        }

    }

    &-comments {

        .comment-form-comment,
        .comment-form-author,
        .comment-form-email {
            @include flex(column, null, null, null, flex);
            font-size: 1.6rem;

        }        

        .comment-list {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;

                &:before{
                    content: '';
                    width: 0;
                    height: 0;
                    margin: 0;
                }

            }

        }

        .comment-body {
            @include flex(column, null, center, center, flex);
            margin: 15px;
            padding: 45px;
            box-sizing: border-box;
            background: #f5f5f5;
            border-radius: 10px;

            .avatar {
                border-radius: 50%;
                margin: 30px;
            }

            .says {display:none;}

            .comment-author {
                @include flex(column, null, center, null, flex);
                font-size: 1.6rem;

                @include media(tablet) {
                    @include flex(row);

                }

                .fn {
                    font-style: normal;
                    font-weight: 600;

                }
            }

            @include media(tablet) {
                @include flex(row, null, center, normal);
            }

        }

        .comment-data {
            margin: 0 15px;
        }

        .comment-respond {
            padding: 15px;
            background: #f5f5f5;
            border-radius: 10px;

            margin: 60px 15px;

            @include media(mobile) {
                padding: 45px;

            }

        }

        .comment-reply {

            &-title {
                text-align: center;

            }

        }

        .comment-notes {
            text-align: center;

        }

    }    

}

.single {

    .reply {
        @include flex(row, null, center, center);
    }

    .comment-reply-link {
        display: block;
        background: $indigo;
        border-radius: 30px;
        padding: 15px 30px;
        color: #fff;
        transition: 0.2s all ease-in-out;

        &:hover {
            background: #303030;
            color: #fff;
        }

    }

}

@include media(desktop) {

    .blog {

        &-list {
            @include flex(row, nowrap);

        }

    }

}

.comments-title {
    margin: 0 15px;
}

@media screen and (max-width:960px) {
	.rwd-table {
		overflow-x:scroll;
	}	
}

@media screen and (min-width:960px) {
	table {
		width:100%;
		table-layout: fixed;
	}
}

