.about {
    @include flex(column, wrap, center, center, flex);

    &-left {
        @include flex(column, wrap, null, null, flex);
        max-width: 100%;
    }

    &-right {
        max-width: 100%;
    }
    
    &-title {
        font-size: 4rem;
        font-weight: normal;
        color: $indigo;
        margin: 0;
        padding: 0;

    }

    &-subtitle {
        font-size: 2.5rem;
        font-weight: normal;
        color: $green;
        margin: 0;
        padding: 0;

    }

    &-image {
        @include radius();
        position: relative;
        top: -10px;

    }

    &-content {
        padding: 0 15px;
        box-sizing: border-box;

        p {
            max-width: 600px;

        }

    }

    &-why {
        @include flex(row, nowrap, null, null, flex);
        max-width: 560px;

        &__svg {
            margin: 0 auto;
        }

        &__box {
            @include radius();
            flex: 1;
            margin: 0 15px;
            padding: 30px;
            box-sizing: border-box;
            position: relative;
            bottom: 6rem;

            color: #fff;

            &:nth-child(2n) {
                background: $indigo;

            }

            &:nth-child(2n+1) {
                background: $green;
            }

            p {
                font-size: 1.4rem;
            }

        }

    }

    &-experience {
        color: $indigo;
        background: #e1e1e1;
        @include radius();
        padding: 15px;

        text-align: center;

        h3 {
            font-size: 3.6rem;
            max-width: 600px;
        }

    }

}

@include media (desktop) {

    .about {
        @include flex(row);

        &-image {
            top: 0;
            left: -10px;

        }

        &-right {
            flex: 1;
        }

        &-content{
            padding: 0 8rem;
        }

        &-experience {
            margin: 4rem 0 0 4rem;
            padding: 6rem;
            text-align: left;

        }

    }

}

@include media (fhd) {

    .about {

        &-content {

            p {
                max-width: 900px;
                
            }

        }

    }

}