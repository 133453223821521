.btn {
    @include radius();
    @include transition();
    display: block;
    font-size: 1.8rem;
    font-weight: $bold;

    text-align: center;
    padding: 2rem 4rem;
    margin: 5px 0;

    border: 0;

    text-transform: uppercase;
    max-width: 320px;

    &-fill{

        &-white {
            background: #fff;
            color: $indigo;

            &:hover {
                background: $indigo;
                color: #fff;

            }

        }

        &-indigo {
            background: $indigo;
            color: #fff;

            &:hover {
                background: #fff;
                color: $indigo;

            }
        }
        
    }

    &-border {
        border: 2px solid;

        &--white { 
            border-color: #fff;
            color: #fff;

            &:hover {
                background: #fff;
                color: $indigo;

            }

        }

        &--indigo {
            border-color: $indigo;
            color: $indigo;

            &:hover {
                background: $indigo;
                color: #fff;

            }

        }

    }

    &-reviews,
    &-blog {
        margin: 0 auto 30px!important;
        position: relative;
        top: 50px;

    }

    &-group {
        @include flex(column, wrap, null, null, flex);

    }

}

@include media(desktop) {

    .btn {
        margin: 5px 15px 0 0;

        &-group {
            @include flex(row);

        }

    }

}
