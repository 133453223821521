html, body {
    margin: 0;
    padding: 0;
}
a {
    text-decoration: none;
}
img {
    max-width: 100%;
    height: auto;
}