.nav {
    display: none;

    &-menu {
        position: fixed;
        width: 240px;
        height: 100vh;
        top: 0;
        left: 0;
        background: $indigo;
        padding: 0;
        margin: 0;
        list-style-type: none;
        z-index: 999;

        &__item {

            a {
                display: block;
                font-size: 1.4rem;
                color: #fff;
                padding: 2rem;
                margin: 10px;

                &:hover {
                    background: #fff;
                    color: $indigo;
                }

            }

        }

    }

    &-footer {
        list-style: none;
        margin: 0;
        padding: 0;
         
        .nav-menu__item {
            float: none;

            a {
                color: #fff;

                &:hover {
                    color: $green;

                }

            }

        }

    }

    &-toggler {
        @include flex(null, null, null, flex-end, flex);
        flex: 1;
        padding: 20px;
        cursor: pointer;
    }

    &-brand {
        flex: 1;

        a {
            display: block;
        }

    }

    &-logo {
        padding: 20px;
    }

    .overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba( 0, 0, 0, 0.8);

        &:after {
            content: '+';
            font-weight: bold;
            transform: rotate(45deg);
            position: absolute;
            color: #fff;
            top: 5px;
            right: 5px;
            font-size: 10rem;

        }

    }

}

@include media(desktop) {

    .nav {
        @include flex(null, null, center, flex-end, flex);
        flex: 1;

        &-brand{
            flex: none;
        }

        &-toggler {
            display: none;

        }

        &-menu {
            position: relative;
            top: 0;
            left: 0;
            width: auto;
            height: auto;
            background: #fff;

            &__item {
                float:left;

                a {
                    display: block;
                    font-size: 1.6rem;
                    color: $indigo;
                    padding: 1rem;
                    @include transition();
                    @include radius();

                    &:hover {
                        background: $indigo;
                        color: #fff;

                    }

                }

                &:last-child {

                    a {
                        background: $green;
                        color: #fff;

                        &:hover {
                            color: $indigo;
                            background: #f9f9f9;

                        }
                        
                    }

                }

            }

        }

        &-footer {
             
            .nav-menu__item {
                
                &:last-child {

                    a {
                        background: none;
                        color: #fff;

                        &:hover {
                            color: $green;
                            background: none;

                        }
                        
                    }

                }
    
            }
    
        }

    }

    .overlay {
        display: none;
    }

}

@include media(laptop) {

    .nav {

        &-menu {

            &__item {

                a {
                    padding: 1.5rem 2rem;
                }

            }

        }

    }

}
