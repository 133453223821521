.page {

    &-thumbnail {

        img {
            @include radius();
            margin-bottom: 15px;

        }

    }

    &-container {
        box-sizing: border-box;
        padding: 0 15px;

        ul,
        ol {
            list-style-type: none;
                
            li {
                font-size: 20px;
                margin: 15px 0;
    
                &:before {
                    content: "";
                    display: inline-block;
                    width: 5px;
                    height: 5px;
                    margin-right: 15px;
                    margin-bottom: 2px;
                    background: $indigo;
    
                }
            }
    
        }

        blockquote {
            font-size: 2rem;
            border-left: 2px solid $indigo;
            padding-left: 20px;
            font-style: italic;

            cite {
                font-size: 1.6rem;

            }

        }

        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 2.4rem;

        }

        img:not(.wp-post-image):not(.avatar){
            max-width: 100%;
            height: auto;
            -webkit-box-shadow: 0 10px 64px 0 rgba(0,0,0,.15);
            box-shadow: 0 10px 64px 0 rgba(0,0,0,.15);
        }

        a {
            color: $green;
            @include transition();

            &:hover {
                color: $indigo;

            }

        }

    }

}
